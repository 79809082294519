import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import { Link, useLocation } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/16/solid'; // Import XMarkIcon for close button

const navbarOptions = [
  {
    name: "Home",
    link: "/",
    sublinks: [],
  },
  {
    name: "About Us",
    sublinks: [
      {
        title: "Company Profile",
        url: "/about/company-profile",
      },
      {
        title: "Vision & Mission",
        url: "/about/vision-mission",
      },
      {
        title: "Management",
        url: "/about/management",
      },
    ],
  },
  {
    name: "Products",
    sublinks: [
      {
        title: "API Products",
        url: "/products/api",
      },
      {
        title: "API Intermediates",
        url: "/products/api-intermediates",
      },
      // {
      //   title: "API Impurities",
      //   url: "/products/api-impurities",
      // },
      {
        title: "Fine Chemicals",
        url: "/products/special-chemicals",
      },
    ],
  },
  {
    name: "Enterprise",
    sublinks: [
      {
        title: "R&D",
        url: "/Research",
      },
      {
        title: "Global Presence",
        url: "/GlobalPre",
      },
      // {
      //   title: "API Impurities",
      //   url: "/products/api-impurities",
      // },
      {
        title: "Infrastructure",
        url: "/Infra",
      },
    ],
  },
  
  // {
  //   name: "Careers",
  //   link: "/career",
  //   sublinks: [],
  // },
  // {
  //   name: "Media",
  //   link: "/media",
  //   sublinks: [],
  // },
  {
    name: "Contact Us",
    link: "/contact-us",
    sublinks: [],
  },
];

function Navbar() {

  const handleLogoClick = (e) => {
    e.preventDefault(); // Prevent any default link behavior
    window.location.reload(); // Reload the page
  };


  const [navbarOpen, setNavbarOpen] = useState(false); // Control mobile menu open state
  const [openMenu, setOpenMenu] = useState(null); // Track which dropdown is open
  const location = useLocation();

  const handleLinkClick = () => {
    setNavbarOpen(false); // Close the menu when any link is clicked
    setOpenMenu(null); // Close any open dropdown when link clicked
  };

  const toggleMenu = (index) => {
    // Toggle the menu if it's already open, otherwise open the new one
    setOpenMenu(openMenu === index ? null : index);
  };
  

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'about') {
      return;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <div className="navbar">
      <ul className="navbar-menu">
        <li onClick={handleLogoClick} >
          <Link to="/" >
           <Logo />
          </Link>
         
        </li>
        {navbarOptions.map((navBar, index) => (
          <li key={index} className={index === 0 ? 'active' : ''}>
            {navBar.sublinks.length > 0 ? (
              <span onClick={() => toggleMenu(index)}>
                {navBar.name}
              </span>
            ) : (
              <span>
                <Link to={navBar.link}>{navBar.name}</Link>
              </span>
            )}
            {/* Show sublinks only if the menu is open */}
            {openMenu === index && navBar.sublinks.length > 0 && (
              <div className="sublinks">
                <ul>
                  {navBar.sublinks.map((sl, idx) => (
                    <li key={idx}>
                      <Link to={sl.url} onClick={handleLinkClick}>
                        {sl.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
        <li>
          {/* Toggle between hamburger and close icons */}
          {navbarOpen ? (
            <XMarkIcon
              className="hamburger-icon"
              onClick={() => setNavbarOpen(!navbarOpen)}
            />
          ) : (
            <Bars3Icon
              className="hamburger-icon"
              onClick={() => setNavbarOpen(!navbarOpen)}
            />
          )}
        </li>
      </ul>

      {navbarOpen && (
        <div className="mobile-menu">
          <ul>
            {navbarOptions.map((nav, idx) => (
              <li key={idx}>
                {nav.sublinks.length > 0 ? (
                  <>
                    <span className='sub-line'  onClick={() => toggleMenu(idx)}>
                      {nav.name}
                    </span>
                    {openMenu === idx && (
                      <ul className='nav-sublinks'>
                        {nav.sublinks.map((nv, i) => (
                          <li key={i}>
                            <Link to={nv.url} onClick={handleLinkClick}>
                              {nv.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <Link to={nav.link} onClick={handleLinkClick}>
                    {nav.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;
