import { AdjustmentsVerticalIcon, BriefcaseIcon, LightBulbIcon, TruckIcon } from '@heroicons/react/16/solid';
import React from 'react';
import { Link } from 'react-router-dom';

function OurServices() {
  return (
    <div className='services'>
        <div className='heading'>
            <h1>Our Core Services</h1>
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
        </div>
        <ul>
            <li>
                <div className='service-card-1'>
                    <div className='icon-box' style={{backgroundColor:'#0646b829'}}>
                        <LightBulbIcon />
                    </div>
                    <p className='title'>R & D</p>
                    <p className='description'>
                    Our research and development endeavours are built on the three pillars of innovation, customization and quality. Innovation helps to fight diseases which have developed resistance.
                    </p>
                    <Link className='home-box-Link' to="/research">
                    Learn More
                    </Link>
                </div>
            </li>
            <li>
                <div className='service-card-2'>
                    <div className='icon-box' style={{backgroundColor:'#0646b829'}}>
                        <BriefcaseIcon />
                    </div>
                    <p className='title'>GLOBAL PRESENCE</p>
                    <p className='description'>
                    We have our presence in more than 80 countries. With established presence in South East Asia, Asia Pacific, Africa, North America, EU & EEA, MENA, Latin America and CIS countries.
                    </p>
                    <Link className='home-box-Link' to ="/globalpre"> Learn More</Link>
                </div>
            </li>
            <li>
                <div className="service-card-3">
                    <div className='icon-box' style={{backgroundColor:'#0646b829'}}>
                        <TruckIcon />
                    </div>
                    <p className='title'>PRODUCTS</p>
                    <p className='description'>
                    At present, more than 26 products are being manufactured here with 120 members of our supporting staff. We have a WHO GMP approved Loan License manufacturing facility.
                    </p>
                    <Link className='home-box-Link' to="/products">Learn More</Link>
                </div>
            </li>
            <li>
                <div className='service-card-1'>
                    <div className='icon-box' style={{backgroundColor:'#0646b829'}}>
                        <AdjustmentsVerticalIcon />
                    </div>
                    <p className='title'>QUALITY MATTERS</p>
                    <p className='description'>
                    Our mission is to produce the most effective quality as per the client specifications inside the most effective doable worth with our internationally competitive products and prices.
                    </p>
                    <Link className='home-box-Link' to="/aboutus">Learn More</Link>
                </div>
            </li>
        </ul>
    </div>
  );
}

export default OurServices;
