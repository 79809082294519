import React from "react";
import office from "../components/images/office.jpeg";

function JourneyIntro() {
  return (
    <>
      <div className="journey-intro">
        <ul>
          <li>
            <div className="journey-content">
              <div>
                <h3>About SGMR Pharmaceuticals</h3>
                <br></br>
                <p>
                  About SGMR Pharmaceuticals SGMR Pharmaceuticals Pvt Ltd. is a
                  renowned Loan License manufacturer of API’s and Intermediates.
                  We are headquartered in Hyderabad, Telangana, India. And also
                  we have our marketing branches in Dubai and Manchester(UK). We
                  specialize in the product manufacturing and export of Bulk
                  drugs Products and Intermediates.
                </p>
                <br></br>
                <p>
                  Our business, the methodology aims to realize to completion
                  futuristic synergy of backward integration. We ensure that at
                  no point of time our production commitments to our clients in
                  supplying API products and Extended Products will be met 100%.
                </p>
                {/* <a href="">Learn More</a> */}
              </div>
            </div>
          </li>
          <li>
            <img src={office} />
          </li>
        </ul>
      </div>
    </>
  );
}

export default JourneyIntro;
