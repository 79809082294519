import React from 'react'


function Header({title,subtitle,bkgImage}) {
  return (
    <>
    <div className='header-section' style={{backgroundImage:`url(${bkgImage})`}}>
        <div className='header-content'>
        <p>{subtitle}</p>
        <h1>{title}</h1>
        
        </div>
        
    </div>
    </>
  )
}

export default Header