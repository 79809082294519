import React, { useState } from "react";
import Img1 from "../components/Infrastructure-Img/img1.jpg";
import Img2 from "../components/Infrastructure-Img/img2.jpg";
import Img3 from "../components/Infrastructure-Img/img3.jpg";
import Img4 from "../components/Infrastructure-Img/img4.jpg";
import Img5 from "../components/Infrastructure-Img/img5.jpg";
import Img6 from "../components/Infrastructure-Img/img6.jpg";
import Img7 from "../components/Infrastructure-Img/img7.jpg";
import Img8 from "../components/Infrastructure-Img/img8.jpg";
import Img9 from "../components/Infrastructure-Img/img9.jpg";
import happyGroup from "../components/images/globalimg.png";
import office from "../components/images/office.jpeg";
import Crosss from "../components/svgs/Crosss";
import { useEffect } from "react";
import Next from "../components/svgs/Next";
import Previous from "../components/svgs/Previous";
import infra from '../components/images/infra.jpg'

const Infra = () => {
  const images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

  const [currentIndex, setCurrentIndex] = useState(null);

  const openImage = (index) => {
    setCurrentIndex(index);
  };

  const closeImage = () => {
    setCurrentIndex(null);
  };

  const showPreviousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };



  useEffect(() => {
    const targetElem = document.getElementById("infra");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      <div
        className="carousel"
        style={{ backgroundImage: `url(${infra})` }}
      >
        <div className="carousel-content">
          <div>
            <p className="line1">
              Join us on our journey as we plan to emprove the wellness of human
              kind overall.
            </p>
          </div>
          <div>
            <h1>Infrastructure. </h1>
          </div>
        </div>
      </div>

      <div>
        <div>
          <p className="Infr-heading">
            <center></center>
          </p>

          {/* Image Grid */}
          <div className="image-grid" id="infra">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`img-${index}`}
                onClick={() => openImage(index)}
                className="thumbnail"
              />
            ))}
          </div>

          {/* Full Screen Modal */}
          {currentIndex !== null && (
            <div className="fullscreen-modal">
              <button className="prev-btn" onClick={showPreviousImage}>
                <Previous/>
              </button>
              <img
                src={images[currentIndex]}
                alt={`img-${currentIndex}`}
                className="fullscreen-img"
              />
              <button className="next-btn" onClick={showNextImage}>
                <Next/>
              </button>
              <button className="cancel-btn" onClick={closeImage}>
                <Crosss/>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Infra;
