import React from 'react';
import handCapsule from '../components/images/hand-capsule.png';
import { Link } from 'react-router-dom';

function Products() {
  return (
    <>
    <div className='products' style={{backgroundImage:`url(${handCapsule})`}}>
        <ul>
            <li>
            <div className='product-content'>
                <h1>Explore Our Products..</h1>
                <p>
                Our company has cutting-edge manufacturing facilities that allow us to meet our global goals. 
                With a focus on obtaining consistent regulatory approvals,
                our products are now being distributed worldwide. 
                Synthimed Labs, a rapidly expanding pharmaceutical company, 
                strives to become a leading player in the global industry,
                and change the face of healthcare.
                </p>
                <p>
                As we embark on this transformative journey, our unwavering commitment to excellence in pharmaceutical research, development, and production remains at the forefront of our mission. With this new investment, we are poised to expand our capabilities and deliver even more life-changing medications to those in need.
                </p>
                {/* <a href='' style={{marginBlock:'20px'}}>
                    Learn More
                </a> */}
            </div>
            </li>
            <li>
            
            </li>
        </ul>
    </div>
    <div className='products-kite'>
        <ul>
            <li>
                <div>
                    <Link to="/products/api">Api Products</Link>
                </div>
            </li>
            <li>
                <div>
                    <Link to="/products/api-intermediates">API Intermediates</Link>
                </div>
            </li>
            {/* <li>
                <div>
                    <Link to="/products/api-impurities">Api Impurities</Link>
                </div>
            </li> */}
        </ul>
        <ul>
            <li>
                <div>
                    <Link to="/products/special-chemicals">Fine Chemicals</Link>
                </div>
            </li>
            {/* <li>
                <div>
                    <p>All Products</p>
                </div>
            </li> */}
            
        </ul>
        {/* <ul>
             
            <li>
                <div>
                    <p>View Details</p>
                </div>
            </li>
            
        </ul> */}
    </div>
    </>
  )
}

export default Products