import React from 'react'
import { useEffect } from 'react'
import { BrowserRouter, Routes , Route } from 'react-router-dom'
import Home from './pages/Home'
import CompanyProfile from './pages/CompanyProfile'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import About from './pages/About'
import Innovation from './pages/Innovation'
import SalesMarketting from './pages/SalesMarketting'
import Distribution from './pages/Distribution'
import QualityAndComplaince from './pages/QualityAndComplaince'
import APIProducts from './pages/APIProducts' 
import ApiIntermediates from './pages/ApiIntermediates'
import APIImpurities from './pages/APIImpurities'
import SpecialChemicals from './pages/SpecialChemicals'
import Contact from './pages/Contact'
import Media from './pages/Media'
import Career from './pages/Career'
import Products from './components/Products'
import Research from './pages/Research'
import GlobalPre from './pages/GlobalPre'
import Infra from './pages/Infra'



function App() {

    useEffect(() => {
      document.title = "SGMR Pharmaceuticals Private Limited"; // Set the page title
      
    }, []);


  return (
    <div className='main'>
      <BrowserRouter>
      
       <Navbar/> 
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about/company-profile" element={<About id="2" />}/>
          <Route path="/about/vision-mission" element={<About id="1" />}/>
          <Route path="/about/presence" element={<About id="3" />}/>
          <Route path="/about/management" element={<About id="4"/>}/>
          <Route path="/services/innovation" element={<Innovation id="4"/>}/>
          <Route path="/services/sales-marketting" element={<SalesMarketting id="4"/>}/>
          <Route path="/services/distribution" element={<Distribution id="4"/>}/>
          <Route path="/services/quality-complaince" element={<QualityAndComplaince id="4"/>}/>
          <Route path="/products/api" element={<APIProducts/>}/>
          <Route path="/products/api-intermediates" element={<ApiIntermediates />}/>
          <Route path="/products/api-impurities" element={<APIImpurities />}/>
          <Route path="/products/special-chemicals" element={<SpecialChemicals />}/>
          <Route path="/contact-us" element={<Contact/>}/>
          <Route path="/media" element={<Media/>}/>
          <Route path="/career" element={<Career/>}/>
         <Route path='/home' element={<Home/>}/>
         <Route path='/products' element ={<Products/>}/>
         <Route path ="/contact" element={<Contact/>}/>
         <Route path="/research" element={<Research/>}/>
         <Route path="/globalpre" element={<GlobalPre/>}/>
         <Route  path ="/aboutus" element={<About/>}/>
         <Route path="/Infra" element={<Infra/>}/>



        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App