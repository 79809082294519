import React from "react";
import office from "../components/images/office.jpeg";

const AboutSgmt = () => {
  return (
    <div className="journey-intro">
      <ul>
        <li>
          <div className="about-journey-content">
            <div>
              <h3>About SGMR Pharmaceuticals</h3>
              
              <p>
                SGMR Pharmaceuticals Pvt Ltd. is a renowned Loan License
                manufacturer of API’s and Intermediates. We are headquartered in
                Hyderabad, Telangana, India. And also we have our marketing
                branches in Dubai and Manchester(UK). We specialize in the
                product manufacturing and export of Bulk drugs Products and
                Intermediates. Our customers and buyers include reputed
                pharmaceutical companies of the US, Europe, Middle-East, Africa,
                Latin America, and the CIS region, Asian Countries.
              </p>
              <p>
                Starting from a single product line, SGMR Pharmaceuticals Pvt
                Ltd has emerged as a diversified multi-product company, with
                interests in the production of API Products and API
                Intermediates. We bring together more than 10 years of
                experience in the manufacturing of API Products and
                Intermediates.
              </p>
              <p>
                Our business, the methodology aims to realize to completion
                futuristic synergy of backward integration. We ensure that at no
                point of time our production commitments to our clients in
                supplying API products and Extended Products will be met 100%.
              </p>{" "}
            </div>
          </div>
        </li>
        <li>
          <img src={office} />
        </li>
      </ul>
    </div>
  );
};

export default AboutSgmt;
