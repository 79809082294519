import React from 'react'
import productBg from '../components/images/products-1.jpg';
import Header from '../components/Header';
import {apiProducts} from '../components/api-products';
import { useEffect } from 'react';

function APIProducts() {

    useEffect(() => {
        const targetElem = document.getElementById("content");
        if (targetElem) {
          const elementPosition =
            targetElem.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - 100; // Adjust for the 20px offset
    
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }, []);


  return (
    <div>
        <Header bkgImage={productBg} 
        title="API products"
        subtitle="Our API products are listed down"
        />
        <div className='table-component' id="content">
            <table>
                <thead>
                    <th>S No</th>
                    <th>Category & Products</th>
                    <th>
                        Pharmacopeial Grade
                    </th>
                    <th>
                        CAS No
                    </th>
                   
                </thead>
                {apiProducts.map((product, index) => {
    return (
        <React.Fragment key={index}>
            <tr>
                <td colSpan="4" className='category'>{product.category}</td>
            </tr>
            {product.products.length > 0 &&
                product.products.map((pr) => {
                    return (
                        <tr key={pr.sr_no}>
                            <td>{pr.sr_no}</td>
                            <td>{pr.name}</td>
                            <td>{pr.grade}</td>
                            <td>{pr.cas_no}</td>
                        </tr>
                    );
                })}
        </React.Fragment>
    );
})}

                
            </table>
        </div>
    </div>
  )
}

export default APIProducts;