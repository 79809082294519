import React from 'react';
import logo from '../components/images/logo.png'

function Logo() {
  return (
    <div className='logo'>
         <img src={logo} alt='SGMR Pharma' />
    </div>
  )
}

export default Logo