import React from 'react'
import Header from '../components/Header';
import productBg from '../components/images/products-1.jpg';
import { impurities } from '../components/api-products';

function APIImpurities() {
  return (
    <div>
    <Header bkgImage={productBg} 
    title="Pharmaceuticals Impurities"
    subtitle="Our API products are listed down"
    />
    <div className='table-component'>
        <table>
            <thead>
                <th>S No</th>
                <th>Products</th>
                
            </thead>
            {impurities.map((product, index) => {
return (
    <React.Fragment key={index}>
        <tr>
            <td colSpan="2" className='category'>{product.name}</td>
        </tr>
        {product.products.length > 0 &&
            product.products.map((pr) => {
                return (
                    <tr key={pr.sNo}>
                        <td>{pr.sNo}</td>
                        <td>{pr.product}</td> 
                    </tr>
                );
            })}
    </React.Fragment>
);
})}

            
        </table>
    </div>
</div>
  )
}

export default APIImpurities