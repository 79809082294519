import React from 'react';
import productBg from '../components/images/products-1.jpg';
import Header from '../components/Header';
import { apiIntermediataires } from '../components/api-products';
import { useEffect } from 'react';

function ApiIntermediataires() {
  useEffect(() => {
    const targetElem = document.getElementById("ApiIntermediataires");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      <Header
        bkgImage={productBg}
        title="API Intermediates"
        subtitle="Our API intermediates are listed below"
      />
      <div className="api-intermediates-table" id="ApiIntermediataires">
        <table>
          <thead>
            <tr>
              <th>S No</th>
              <th>Category & Products</th>
              <th>Pharmacopeial Grade</th>
              <th>CAS No</th>
            </tr>
          </thead>
          <tbody>
            {apiIntermediataires.map((product, index) => {
              return (
                <React.Fragment key={index}>
                  <tr className="category-row">
                    <td colSpan="4" className="category-cell">
                      {product.category}
                    </td>
                  </tr>
                  {product.products.length > 0 &&
                    product.products.map((pr) => {
                      return (
                        <tr key={pr.sr_no} className="product-row">
                          <td className="s-no">{pr.sr_no}</td>
                          <td className="product-name">{pr.name}</td>
                          <td className="product-grade">{pr.grade}</td>
                          <td className="cas-no">{pr.cas_no}</td>
                        </tr>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ApiIntermediataires;

