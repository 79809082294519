export const apiIntermediataires = [
    {
        "category": "Atazanavir",
        "products": [
            {
                "sr_no": 1,
                "name": "(AZS-3)3S,8S,9S,12S)-3,12-Bis(1,1-dimethyl ethyl)-8-hydroxy-4,11-dioxo-9-(phenylmethyl)-6-[[4-(2-pyridinyl)phenyl]methyl]-2,5,6,10,13-pentaazatetradecanedioic acid dimethyl ester",
                "grade": "Commercial",
                "cas_no": "1292296-09-3"
            }
        ]
    },
    {
        "category": "Azilsartan",
        "products": [
            {
                "sr_no": 2,
                "name": "Methyl 1-[(2’-cyanobiphenyl-4-yl)methyl]-2-ethoxy-1h-benzimidazole-7-carboxylate",
                "grade": "Under Development",
                "cas_no": "139481-44-0"
            },
            {
                "sr_no": 3,
                "name": "Methyl 2-ethoxybenzimidazole-7-carboxylate",
                "grade": "Under Development",
                "cas_no": "150058-27-8"
            }
        ]
    },
    {
        "category": "Biperiden Base",
        "products": [
            {
                "sr_no": 4,
                "name": "Alpha-5-Norbornene-2-yl-alphaphentl-1-piperidinepropanol",
                "grade": "Commercial",
                "cas_no": "514-65-8"
            },
            {
                "sr_no": 5,
                "name": "Cyclo propyl Amine (CPA)",
                "grade": "Commercial",
                "cas_no": "765-30-0"
            }
        ]
    },
    {
        "category": "Dabigartan",
        "products": [
            {
                "sr_no": 6,
                "name": "{[4-(5-oxo-4,5-dihydro-1,2,4-oxadiazol-3-yl)phenyl]amino}acetic acid",
                "grade": "Commercial",
                "cas_no": "872728-82-0"
            },
            {
                "sr_no": 7,
                "name": "Ethyl 3-[{[3-methyl-4-(methylamino)phenyl]carbonyl}(pyridin-2-yl)amino]propanoate",
                "grade": "Commercial",
                "cas_no": "212332-56-0"
            },
            {
                "sr_no": 8,
                "name": "Dapoxetine Base",
                "grade": "Commercial",
                "cas_no": "119356-77-3"
            }
        ]
    },
    {
        "category": "Darunavir",
        "products": [
            {
                "sr_no": 9,
                "name": "2,5-Dioxopyrrolidin-l-yl(3R,3aS,6aR)-hexahydro[2,3-b]furan-3-yl carbonate",
                "grade": "Commercial",
                "cas_no": "253265-97-3"
            },
            {
                "sr_no": 10,
                "name": "DCC (1,3 –Di cyclo hexyl carbodiimide)",
                "grade": "Commercial",
                "cas_no": "538-75-0"
            },
            {
                "sr_no": 11,
                "name": "Dibenzo-18-crown-6",
                "grade": "Commercial",
                "cas_no": "14187-32-7"
            }
        ]
    },
    {
        "category": "Ezetimibe",
        "products": [
            {
                "sr_no": 12,
                "name": "(3R,4S)-1-(4-fluorophenyl)-3-[(3S)-3-(4-fluorophenyl)-3-hydroxypropyl]-4-(4-benzyloxyphenyl)azetidin-2-one",
                "grade": "Commercial",
                "cas_no": "163222-32-0"
            }
        ]
    },
    {
        "category": "Imatinib Base",
        "products": [
            {
                "sr_no": 13,
                "name": "4-4[(4-methyl-1-piperazininyl)methyl]-N[4-methyl-3-[[4-{3-pyridinyl)-2-pyrimidinyl]ammo]phenyl}benzamide",
                "grade": "Commercial",
                "cas_no": "152459-95-5"
            },
            {
                "sr_no": 14,
                "name": "Imidazole-5-carboxylic ethyl ester",
                "grade": "Commercial",
                "cas_no": "144689-93-0"
            }
        ]
    },
    {
        "category": "Infigratinib",
        "products": [
            {
                "sr_no": 15,
                "name": "5-Amino-3 (trifluoromethyl) pyridine-2-carbonitrile",
                "grade": "Commercial",
                "cas_no": "573762-62-6"
            },
            {
                "sr_no": 16,
                "name": "1-(6-Chloropyrimidin-4-yl)-3-(2,6-dichloro-3,5-dimethoxyphenyl)-1-methyl urea",
                "grade": "Commercial",
                "cas_no": "--"
            },
            {
                "sr_no": 17,
                "name": "Linezolid",
                "grade": "Commercial",
                "cas_no": "165800-03-3"
            }
        ]
    },
    {
        "category": "Linalidomide",
        "products": [
            {
                "sr_no": 18,
                "name": "3 Amino-piperidine-2,6-dione hydrochloride",
                "grade": "Under Development",
                "cas_no": "24666-56-6"
            },
            {
                "sr_no": 19,
                "name": "2-Bromomethyl-3-nitrobenzoic acid methyl ester",
                "grade": "Under Development",
                "cas_no": "98475-07-1"
            },
            {
                "sr_no": 20,
                "name": "3-(4-Nitro-1-oxo-1,3-dihydro-isoindol-2yl)-piperidine-2,6-dione",
                "grade": "Under Development",
                "cas_no": "827026-45-9"
            },
            {
                "sr_no": 21,
                "name": "MAP (3-Amino Phenol)",
                "grade": "Commercial",
                "cas_no": "591-27-5"
            },
            {
                "sr_no": 22,
                "name": "N-[4-cyano-3-trifluoromethyl phenyl]-3-[4-fluorophenylthio]-2-hydroxy-2methylpropionamide",
                "grade": "Commercial",
                "cas_no": "90356-78-8"
            },
            {
                "sr_no": 23,
                "name": "N,N-Carbonyl diimidazole (CDI)",
                "grade": "Commercial",
                "cas_no": "530-62-1"
            }
        ]
    },
    {
        "category": "Palonosetron",
        "products": [
            {
                "sr_no": 24,
                "name": "(S)-N-(1-Azabicyclo[2.2.2]oct-3-yl)-5,6,7,8-tetrahydro-1-naphthalene carboxamide",
                "grade": "Under Development",
                "cas_no": "135729-78-1"
            },
            {
                "sr_no": 25,
                "name": "5,6,7,8-Tetrahydronaphthoic acid",
                "grade": "Under Development",
                "cas_no": "4242-18-6"
            },
            {
                "sr_no": 26,
                "name": "Piperazine Hydrochloride",
                "grade": "Commercial",
                "cas_no": "142-64-3"
            }
        ]
    },
    {
        "category": "Rivaroxaban",
        "products": [
            {
                "sr_no": 27,
                "name": "4-(4-Aminophenyl)morpholin-3-one",
                "grade": "Commercial",
                "cas_no": "438056-69-0"
            },
            {
                "sr_no": 28,
                "name": "5-Chlorothiophene-2-carboxylic Acid",
                "grade": "Commercial",
                "cas_no": "24065-33-6"
            },
            {
                "sr_no": 29,
                "name": "(R)-4-[4-[(3-Chloro-2-hydroxypropyl)amino]phenyl]morpholin-3-one",
                "grade": "Commercial",
                "cas_no": ""
            },
            {
                "sr_no": 30,
                "name": "Sodium Azide",
                "grade": "Commercial",
                "cas_no": "26628-22-8"
            }
        ]
    },
    {
        "category": "Sitagliptin Phosphate Monohydrate",
        "products": [
            {
                "sr_no": 31,
                "name": "4-Oxo-4-[3-trifluoromethyl)-5,6-dihydro[1,2,4]triazolo[4,3-a] pyrazin-7(8H)-yl]-1-(2,4,5-trifluorophenyl)butan-2-one",
                "grade": "Commercial",
                "cas_no": "119302-20-4"
            },
            {
                "sr_no": 32,
                "name": "(2Z)-4-Oxo-4-[3-trifluoromethyl)-5,6-dihydro[1,2,4]triazolo[4,3-a] pyrazin-7(8H)-yl]-1-(2,4,5-trifluorophenyl)butan-2-en-2-amine",
                "grade": "Commercial",
                "cas_no": "119302-24-8"
            },
            {
                "sr_no": 33,
                "name": "3-(Trifluoromethyl)-5,6,7,8-tetrahydro [1,2,4]triazolo [4,3-a] pyrazine Hydrochloride",
                "grade": "Commercial",
                "cas_no": "119302-19-1"
            }
        ]
    },
    {
        "category": "Tamsulosin",
        "products": [
            {
                "sr_no": 34,
                "name": "5-Acetonyl-2-methoxybenzene sulfonamide",
                "grade": "Commercial",
                "cas_no": "116091-63-5"
            },
            {
                "sr_no": 35,
                "name": "5-[(R)-(2-Aminopropyl)]-2-methoxybenzenesulfonamide hydrochloride",
                "grade": "Commercial",
                "cas_no": "112101-77-6"
            },
            {
                "sr_no": 36,
                "name": "Trityl Chloride",
                "grade": "Commercial",
                "cas_no": "76-83-5"
            },
            {
                "sr_no": 37,
                "name": "5-(4’-Bromomethyl-1,1 biphenyl-2-yl)-1-triphenylmethyl-1H-tetrazole (TTB)",
                "grade": "Commercial",
                "cas_no": "124750-51-2"
            },
            {
                "sr_no": 38,
                "name": "Thiosalicylic Acid",
                "grade": "Commercial",
                "cas_no": "147-93-3"
            },
            {
                "sr_no": 39,
                "name": "2-Thiophene Acetyl Chloride",
                "grade": "Commercial",
                "cas_no": "39098-97-0"
            }
        ]
    },
    {
        "category": "Valacyclovir",
        "products": [
            {
                "sr_no": 40,
                "name": "N-[(Phenylmethoxy)carbonyl]-L-valine 2-[(2-amino-1,6-dihydro-6-oxo-9H-purin-9-yl)methoxy]ethyl ester",
                "grade": "Commercial",
                "cas_no": "124832-31-1"
            },
            {
                "sr_no": 41,
                "name": "Xanthine",
                "grade": "Commercial",
                "cas_no": "69-89-9"
            },
            {
                "sr_no": 42,
                "name": "4-Pentyne-1-ol",
                "grade": "Commercial",
                "cas_no": "5390-04-5"
            }
        ]
    },
    {
        "category": "Zonisamide",
        "products": [
            {
                "sr_no": 43,
                "name": "1,2-Benzisoxazole-3-acetic acid",
                "grade": "Under Development",
                "cas_no": "4865-84-3"
            },
            {
                "sr_no": 44,
                "name": "1,2-Benzisoxazole-3-methanesulfonamide",
                "grade": "Under Development",
                "cas_no": "68291-97-4"
            },
            {
                "sr_no": 45,
                "name": "6-Bromo-3-Hydroxypyrazine-2-Carboxamide",
                "grade": "Commercial",
                "cas_no": "259793-88-9"
            },
            {
                "sr_no": 46,
                "name": "5-Bromoindole",
                "grade": "Commercial",
                "cas_no": "10075-50-0"
            },
            {
                "sr_no": 47,
                "name": "6-Fluoro-3-Hydroxypyrazine-2-Carbonitrile Dicyclohexylamine Salt",
                "grade": "Commercial",
                "cas_no": "1137606-74-6"
            }
        ]
    },
    {
        "category": "NOT DEFINED",
        "products": [
            {
                "sr_no": 48,
                "name": "6-Bromo-3-Hydroxypyrazine-2-Carboxamide",
                "grade": "Commercial",
                "cas_no": "259793-88-9"
            }
        ]
    },
    {
        "category": "NOT DEFINED",
        "products": [
            {
                "sr_no": 49,
                "name": "5-Bromoindole",
                "grade": "Commercial",
                "cas_no": "10075-50-0"
            }
        ]
    },
    {
        "category": "NOT DEFINED",
        "products": [
            {
                "sr_no": 50,
                "name": "6-Fluoro-3-Hydroxypyrazine-2-Carbonitrile Dicyclohexylamine Salt",
                "grade": "Commercial",
                "cas_no": "1137606-74-6"
            }
        ]
    }
];


export  const apiProducts = [
    {
        category: "Anti Asthmatic",
        products: [
            {
                sr_no: 1,
                name: "Varenicline Tartrate",
                grade: "–",
                cas_no: ""
            }
        ]
    },
    {
        category: "Anti Bacterial",
        products: [
            {
                sr_no: 2,
                name: "Moxifloxacin Hcl",
                grade: "USP/BP/IP/EP",
                cas_no: "192927-63-2"
            },
            {
                sr_no: 3,
                name: "Tigecycline",
                grade: "–",
                cas_no: "220620-09-7"
            }
        ]
    },
    {
        category: "Anti Cancer",
        products: [
            {
                sr_no: 4,
                name: "Anastrazole",
                grade: "–",
                cas_no: "120511-73-1"
            },
            {
                sr_no: 5,
                name: "Apalutamide",
                grade: "–",
                cas_no: "956104-40-8"
            },
            {
                sr_no: 6,
                name: "Bendamustine",
                grade: "–",
                cas_no: "3543-75-7"
            },
            {
                sr_no: 7,
                name: "Camostat Mesylate",
                grade: "–",
                cas_no: "59721-29-8"
            },
            {
                sr_no: 8,
                name: "Capecitabine",
                grade: "–",
                cas_no: "154361-50-9"
            },
            {
                sr_no: 9,
                name: "Chlorambucil",
                grade: "–",
                cas_no: "305-03-3"
            },
            {
                sr_no: 10,
                name: "Gemcitabine",
                grade: "–",
                cas_no: "95058-81-4"
            },
            {
                sr_no: 11,
                name: "Imatinib",
                grade: "–",
                cas_no: "152459-95-5"
            },
            {
                sr_no: 12,
                name: "Infigratinib",
                grade: "–",
                cas_no: "872511-34-7"
            },
            {
                sr_no: 13,
                name: "Letrozole",
                grade: "–",
                cas_no: "112809-51-5"
            },
            {
                sr_no: 14,
                name: "Melphalan",
                grade: "–",
                cas_no: "148-82-3"
            }
        ]
    },
    {
        category: "Anti-Cholinergic",
        products: [
            {
                sr_no: 15,
                name: "Biperiden HCL",
                grade: "USP/EP 7/JP",
                cas_no: "514-65-8"
            }
        ]
    },
    {
        category: "Anti Coagulant",
        products: [
            {
                sr_no: 16,
                name: "Dabigatran Etexilate Mesylate",
                grade: "IH",
                cas_no: "87228-81-9"
            }
        ]
    },
    {
        category: "Anti Convulsant",
        products: [
            {
                sr_no: 17,
                name: "Lacosamide",
                grade: "–",
                cas_no: "175481-36-4"
            },
            {
                sr_no: 18,
                name: "Levetiracetam",
                grade: "–",
                cas_no: "102767-28-2"
            },
            {
                sr_no: 19,
                name: "Pregabalin",
                grade: "IP/IH/USP/EP",
                cas_no: "148553-50-8"
            }
        ]
    },
    {
        category: "Anti Diabetic",
        products: [
            {
                sr_no: 20,
                name: "Canagliflozin Hemi Hydrate",
                grade: "–",
                cas_no: "928672-86-0"
            },
            {
                sr_no: 21,
                name: "Dapagliflozin",
                grade: "–",
                cas_no: "461432-26-8"
            },
            {
                sr_no: 22,
                name: "Empagliflozin",
                grade: "–",
                cas_no: "864070-44-0"
            },
            {
                sr_no: 23,
                name: "Linagliptin",
                grade: "–",
                cas_no: "668270-12-0"
            },
            {
                sr_no: 24,
                name: "Sitagliptin",
                grade: "–",
                cas_no: "486460-32-6"
            },
            {
                sr_no: 25,
                name: "Vildagliptin",
                grade: "–",
                cas_no: "274901-16-5"
            }
        ]
    },
    {
        category: "Anti Depressant",
        products: [
            {
                sr_no: 26,
                name: "Dapoxetine Hcl",
                grade: "IHS",
                cas_no: "129938-20-1"
            },
            {
                sr_no: 27,
                name: "Trazodone",
                grade: "–",
                cas_no: "19794-93-5"
            }
        ]
    },
    {
        category: "Anti Emetic",
        products: [
            {
                sr_no: 28,
                name: "Domperidone",
                grade: "IP/BP/ph.Eur",
                cas_no: "57808-66-9"
            }
        ]
    },
    {
        category: "Anti Fibrinolytic",
        products: [
            {
                sr_no: 29,
                name: "Epsilon Aminocaproic acid",
                grade: "–",
                cas_no: "60-32-2"
            }
        ]
    },
    {
        category: "Anti folate",
        products: [
            {
                sr_no: 30,
                name: "Pemetrexed disodium",
                grade: "–",
                cas_no: "357166-29-1"
            }
        ]
    },
    {
        category: "Anti Fungal",
        products: [
            {
                sr_no: 31,
                name: "Itraconazole",
                grade: "BP/USP/Ph.Eur",
                cas_no: "84625-61-6"
            },
            {
                sr_no: 32,
                name: "Voriconazole",
                grade: "–",
                cas_no: "137234-62-9"
            }
        ]
    },
    {
        category: "Anti Histamine",
        products: [
            {
                sr_no: 33,
                name: "Cetirizine",
                grade: "EP/IP/USP/Ph.Eur",
                cas_no: "83881-51-0"
            },
            {
                sr_no: 34,
                name: "Fexofenadine Hcl",
                grade: "IP/EP/USP",
                cas_no: "83799-24-0"
            },
            {
                sr_no: 35,
                name: "Levocetirizine",
                grade: "IP/IHS",
                cas_no: "130018-77-8"
            },
            {
                sr_no: 36,
                name: "Montelukast Sodium",
                grade: "IP/BP",
                cas_no: "158966-92-8"
            },
            {
                sr_no: 37,
                name: "Fematodine",
                grade: "IP/USP",
                cas_no: "76824-35-6"
            }
        ]
    },
    {
        category: "Anti Hypertensive",
        products: [
            {
                sr_no: 38,
                name: "Nebivolol Hcl",
                grade: "IP",
                cas_no: "152520-56-4"
            },
            {
                sr_no: 39,
                name: "Olmesartan Medoxomil",
                grade: "USP",
                cas_no: "144689-63-4"
            },
            {
                sr_no: 40,
                name: "Telmisartan",
                grade: "USP",
                cas_no: "144701-48-4"
            },
            {
                sr_no: 41,
                name: "Bisoprolol Fumerate",
                grade: "USP/EP",
                cas_no: "104344-23-2"
            }
        ]
    },
    {
        category: "Anti Hyper Lipidemic",
        products: [
            {
                sr_no: 42,
                name: "Ezetimibe",
                grade: "–",
                cas_no: "163222-33-1"
            }
        ]
    },
    {
        category: "Anti Neoplastic Agent",
        products: [
            {
                sr_no: 43,
                name: "Abiraterone acetate",
                grade: "USP",
                cas_no: "154229-18-2"
            }
        ]
    },
    {
        category: "Anti Lipemic",
        products: [
            {
                sr_no: 44,
                name: "Rosuvastatin",
                grade: "IP/EP",
                cas_no: "287714-41-4"
            }
        ]
    },
    {
        category: "Anti Parkinsonian",
        products: [
            {
                sr_no: 45,
                name: "Rasagiline Mesylate",
                grade: "_",
                cas_no: "163521-08-2"
            }
        ]
    },

    {
        "category": "Anti Retroviral",
        "products": [
            {
                "sr_no": 46,
                "name": "Raltegravir Potassium",
                "grade": "IP/USP/HIS",
                "cas_no": "871038-72-1"
            }
        ]
    },
    {
        "category": "Anti Tuberculosis",
        "products": [
            {
                "sr_no": 47,
                "name": "4-Amino (Para Amino) Salicylic Acid (PAS Acid)",
                "grade": "USP",
                "cas_no": "65-49-6"
            },
            {
                "sr_no": 48,
                "name": "Sodium Amino Salicylate (PAS Sodium)",
                "grade": "IP/USP/BP/EP",
                "cas_no": "6018-19-5"
            }
        ]
    },
    {
        "category": "Anti Thrombotic",
        "products": [
            {
                "sr_no": 49,
                "name": "Prasugrel Hydrochloride",
                "grade": "---",
                "cas_no": "389574-19"
            }
        ]
    },
   
    {
        category: "Anti Ulcerative",
    products: [
        {
            "sr_no": 50,
            "name": "Esomeprazole Magnesium Dihydrate",
            "grade": "IP/USP",
            "cas_no": "217087-10-0"
        },
        {
            "sr_no": 51,
            "name": "Fluconazole",
            "grade": "_",
            "cas_no": "86386-73-4"
        },

        {
            "sr_no": 52,
            "name": "Lansoprazole",
            "grade": "IP/BP/USP/Ph.Eur",
            "cas_no": "103577-45-3"
        },
        {
            "sr_no": 53,
            "name": "Omeprazole",
            "grade": "IP/BP/USP/Ph.Eur",
            "cas_no": "73590-58-6"
        },

        {
            "sr_no": 54,
            "name": "Pantoprazole Sodium Sesqui hydrate",
            "grade": "IP/BP/USP",
            "cas_no": "164579-32-2"
        },

        {
            "sr_no": 55,
            "name": "Posaconazole",
            "grade": "_",
            "cas_no": "171228-49-2"
        },

        {
            "sr_no": 56,
            "name": "Rabeprazole",
            "grade": "IP/USP/IHS",
            "cas_no": "117976-89-3"
        }
    ]
    },
    {
        category: "Anti Viral",
        products: [
            {
                sr_no: 57,
                name: "Oseltamivir Phosphate",
                grade: "-",
                cas_no: "204255-11-8"
            },
            {
                sr_no: 58,
                name: "Favipiravir",
                grade: "HI",
                cas_no: "259793-96-94"
            },
            {
                sr_no: 59,
                name: "Molnupiravir",
                grade: "HI",
                cas_no: "2349386-89-4"
            }
        ]
    },

    {
        category: "Antagonist",
        products: [
            {
                "sr_no": 60,
                "name": "Losartan Potassium",
                "grade": "IP/USP",
                "cas_no": "124750-99-8"
            },
            {
                "sr_no": 61,
                "name": "Ticagrelor",
                "grade": "---",
                "cas_no": "274693-27-5"
            }
            
        ]
    },
    {
        "category": "Angiotensin",
        "products": [
            {
                "sr_no": 62,
                "name": "Valsartan",
                "grade": "IP/USP",
                "cas_no": "137862-53-4"
            }
        ]
    },
    {
        "category": "Benign prostatic Hypertrophy",
        "products": [
            {
                "sr_no": 63,
                "name": "Tamsulosin Hcl",
                "grade": "IP/BP/USP/Ph.Eur",
                "cas_no": "106133-20-4"
            }
        ]
    },
    {
        "category": "Erectile Dysfunction",
        "products": [
            {
                "sr_no": 64,
                "name": "Tadalafil",
                "grade": "EP/USP",
                "cas_no": "171596-29-5"
            }
        ]
    },
    {
        "category": "Hyper Lipidemic",
        "products": [
            {
                "sr_no": 65,
                "name": "Atorvastatin Calcium",
                "grade": "IP/USP",
                "cas_no": "344423-98-9"
            }
        ]
    },
    {
        "category": "Hyper Tension",
        "products": [
            {
                "sr_no": 66,
                "name": "Azilsartan Medoxomil",
                "grade": "---",
                "cas_no": "863031-21-4"
            }
        ]
    },
    {
        "category": "Muscle Relaxant",
        "products": [
            {
                "sr_no": 67,
                "name": "Dantrolene Sodium",
                "grade": "---",
                "cas_no": "7261-97-4"
            }
        ]
    },
    {
        "category": "Nootropic",
        "products": [
            {
                "sr_no": 68,
                "name": "Citicoline Sodium",
                "grade": "---",
                "cas_no": "3381815-4"
            }
        ]
    },
    {
        "category": "Nicotine Addiction",
        "products": [
            {
                "sr_no": 69,
                "name": "Varenicline Tartrate",
                "grade": "---",
                "cas_no": "375815-87-5"
            }
        ]
    },
    {
        "category": "Ophthalmologic Agent",
        "products": [
            {
                "sr_no": 70,
                "name": "Lifitegrast",
                "grade": "---",
                "cas_no": "---"
            }
        ]
    },
    {
        "category": "Urinary Incontinence",
        "products": [
            {
                "sr_no": 71,
                "name": "Solifenacin Succinate",
                "grade": "---",
                "cas_no": "242478-38-2"
            }
        ]
    },
    {
        "category": "Veterinary Drug",
        "products": [
            {
                "sr_no": 72,
                "name": "Ethopabate",
                "grade": "BP/USP",
                "cas_no": "59-06-3"
            }
        ]
    },
    {
        "category": "Prostaglandins",
        "products": [
            {
                "sr_no": 73,
                "name": "Latanoprost",
                "grade": "USP/IH",
                "cas_no": "13209-82-4"
            },

            {
                "sr_no": 74,
                "name": "Misoprost",
                "grade": "USP",
                "cas_no": "59122-46-2"
            },
            
            {
                "sr_no": 75,
                "name": "Bimatoprost",
                "grade": "USP",
                "cas_no": "155206-00-1"
            },

            {
                "sr_no": 76,
                "name": "Travoprost",
                "grade": "USP/IH",
                "cas_no": "157283-68-6"
            },
            {
                "sr_no": 77,
                "name": "Carboprost",
                "grade": "USP",
                "cas_no": "58551-69-2"
            }

        ]
    },
    
    
];

export const impurities =[
      {
        "name": "WRS & Impurities",
        "products": [
          { "sNo": 1, "product": "Atorvastatin Calcium" },
          { "sNo": 2, "product": "Acyclovir" },
          { "sNo": 3, "product": "Azithromycin" },
          { "sNo": 4, "product": "Artemether" },
          { "sNo": 5, "product": "Brexpiprazole" },
          { "sNo": 6, "product": "Sumatriptan" },
          { "sNo": 7, "product": "Clarithromycin" },
          { "sNo": 8, "product": "Cyanocobalamine" },
          { "sNo": 9, "product": "Carbamazepine" },
          { "sNo": 10, "product": "Cyclophosphamide" },
          { "sNo": 11, "product": "Deferasirox HCl" },
          { "sNo": 12, "product": "Donepezil" },
          { "sNo": 13, "product": "Eletriptan" },
          { "sNo": 14, "product": "Efavirenz" },
          { "sNo": 15, "product": "Erythromycin" },
          { "sNo": 16, "product": "Zolmitriptan" },
          { "sNo": 17, "product": "Lamivudine" },
          { "sNo": 18, "product": "Losartan" },
          { "sNo": 19, "product": "Linagliptin" },
          { "sNo": 20, "product": "Olmesartan" },
          { "sNo": 21, "product": "Pravastatin" },
          { "sNo": 22, "product": "Pinaverium Bromide" },
          { "sNo": 23, "product": "Rosuvastatin Calcium" },
          { "sNo": 24, "product": "Tenofovir" },
          { "sNo": 25, "product": "Valsartan" },
          { "sNo": 26, "product": "Bifonazole" },
          { "sNo": 27, "product": "Lamotrigine" },
          { "sNo": 28, "product": "Nepafenac" },
          { "sNo": 29, "product": "Montelukast Sodium" },
          { "sNo": 30, "product": "Methyl Prednisolone" }
        ]
      },
      {
        "name": "Deuterated Compounds",
        "products": [
          { "sNo": 31, "product": "Saroglitazar D4" },
          { "sNo": 32, "product": "Droxidopa 13C6" },
          { "sNo": 33, "product": "Granisetron D3" },
          { "sNo": 34, "product": "Fluticasone Propionate D3" },
          { "sNo": 35, "product": "Ribociclib D6" },
          { "sNo": 36, "product": "Isosorbide Dinitrate 13C6" },
          { "sNo": 37, "product": "All Trans Acitretin D3" },
          { "sNo": 38, "product": "Cycloserine 15ND3" },
          { "sNo": 39, "product": "Cabergoline D6" },
          { "sNo": 40, "product": "O-Desmethyl Mebeverin D5" },
          { "sNo": 41, "product": "Oseltamivir D3 Acid" },
          { "sNo": 42, "product": "Selexipeg D7" },
          { "sNo": 43, "product": "Minoxidil D10" },
          { "sNo": 44, "product": "Loperamide D6" },
          { "sNo": 45, "product": "Hydroxychloroquine d4" },
          { "sNo": 46, "product": "Deferasirox D4" },
          { "sNo": 47, "product": "Rivaroxaban D4" },
          { "sNo": 48, "product": "Theophylline D6" },
          { "sNo": 49, "product": "Nitrofurantoin 13C3" },
          { "sNo": 50, "product": "Varenicline D4" },
          { "sNo": 51, "product": "Fampridine D4" },
          { "sNo": 52, "product": "Rizatriptan d6" },
          { "sNo": 53, "product": "Octreotide d8" },
          { "sNo": 54, "product": "Trans-Phytonadione-d7" },
          { "sNo": 55, "product": "Terbinafine D6" },
          { "sNo": 56, "product": "Hydrochlorothiazide 13C 15N2 D2" },
          { "sNo": 57, "product": "Methotrexate D3" },
          { "sNo": 58, "product": "Dabigatran 13C6" },
          { "sNo": 59, "product": "Dexlansoprazole D4" },
          { "sNo": 60, "product": "Carbamazepine D10" }
        ]
      },
      {
        "name": "Nitrosamine Impurities",
        "products": [
          { "sNo": 61, "product": "NPYR = N-Nitrosopyrrolidine" },
          { "sNo": 62, "product": "NMOR = N-Nitrosomorpholine" },
          { "sNo": 63, "product": "NDPA = N-Nitrosodipropylamine" },
          { "sNo": 64, "product": "NPIP = N-Nitrosopiperidine" },
          { "sNo": 65, "product": "NDMA = N-Nitrosodimethylamine" },
          { "sNo": 66, "product": "NIEA = N-Nitrosoisopropylethylamine" },
          { "sNo": 67, "product": "NDEA = N-Nitrosodiethylamine" },
          { "sNo": 68, "product": "NDIPA = N-Nitroso-di-isopropylamine" },
          { "sNo": 69, "product": "NEIA / NMIPA = N-Ethyl-N-nitroso-2-propanamine" },
          { "sNo": 70, "product": "NMBA = N-Nitroso-N-Methyl-4-Aminobutyric Acid" },
          { "sNo": 71, "product": "NDBA = N-Nitrosodibutylamine" },
          { "sNo": 72, "product": "NEIPA = N-Nitrosoethylisopropylamine" },
          { "sNo": 73, "product": "NDELA = N-Nitrosodiethanolamine" },
          { "sNo": 74, "product": "NMPA = N-Nitrosomethylphenylamine" },
          { "sNo": 75, "product": "NMEA = N-nitrosomethylethylamine" },
          { "sNo": 76, "product": "EBNA = N-tert-butyl-N-ethylnitrosamine" }
        ]
      },
      {
        "name": "Deuterated Nitrosamine Compounds",
        "products": [
          { "sNo": 1, "product": "NDMA-d6 = N-Nitrosodimethylamine-d6" },
          { "sNo": 2, "product": "NDEA-d10 = N-Nitrosodiethylamine-d10" },
          { "sNo": 3, "product": "NMEA-d3 = N-nitrosomethylethylamine-d3" }
        ]
      },
      {
        "name": "Sitagliptin Related Impurities",
        "products": []
      },
      {
        "name": "Azilsartan Related Impurities",
        "products": []
      },
      {
        "name": "Glimepiride Related Impurities",
        "products": []
      },
      {
        "name": "Latanoprost Related Compounds",
        "products": []
      },
      {
        "name": "Bimatoprost Related Compounds",
        "products": []
      },
      {
        "name": "Travoprost Related Compounds",
        "products": []
      },
      {
        "name": "Carboprost Related Impurities",
        "products": []
      },
      {
        "name": "Misoprostol Related Compounds",
        "products": []
      },
      {
        "name": "Cloprostonol Sodium Related Compounds",
        "products": []
      },
      {
        "name": "Tafluprost Related Compounds",
        "products": []
      },
      {
        "name": "And Many More……………..",
        "products": []
      }
    ] ;  

export const chemicals=[
    {
        "sno": "1.",
        "Chem": "1-Cyano ethyl Benzoic acid",
        "casno": "5537-71-3"
    },
    {
        "sno": "2.",
        "Chem": "1 H-Benzimidazol-2-amine,5-(methylthio)-(9CI)",
        "casno": "67469-01-6"
    },
    {
        "sno": "3.",
        "Chem": "1-Hydrazinylphthalazine",
        "casno": "86-54-4"
    },
    {
        "sno": "4.",
        "Chem": "1,1′-Carbonyldiimidazole",
        "casno": "530-62-1"
    },
    {
        "sno": "5.",
        "Chem": "1,1-Dichloro-1,1-diphenoxy methane",
        "casno": "4885-03-4"
    },
    {
        "sno": "6.",
        "Chem": "1,2-Benzenedisulfonyl Dichloride",
        "casno": "6461-76-3"
    },
    {
        "sno": "7.",
        "Chem": "1,2-Dimethylhydrazine",
        "casno": "540-73-8"
    },
    {
        "sno": "8.",
        "Chem": "1-(3-chlorophenyl)-2-hydroxy-1-propanone",
        "casno": "152943-33-4"
    },
    {
        "sno": "9.",
        "Chem": "1-(3-chlorophenyl)-1-hydroxy-2-propanone",
        "casno": "857233-13-7"
    },
    {
        "sno": "10.",
        "Chem": "2-Aminobutane nitrile",
        "casno": "40651-89-6"
    },
    {
        "sno": "11.",
        "Chem": "2-Bromo-2-cyanoacetamide",
        "casno": "1113-55-9"
    },
    {
        "sno": "12.",
        "Chem": "2-Bromo-6-chloroaniline",
        "casno": "59772-49-5"
    },
    {
        "sno": "13.",
        "Chem": "2-Chloromandelic acid",
        "casno": "10421-85-9"
    },
    {
        "sno": "14.",
        "Chem": "2-(2-Chloroethoxy)acetic acid",
        "casno": "14869-41-1"
    },
    {
        "sno": "15.",
        "Chem": "2-(Chloromethyl)-4-methylquinazoline",
        "casno": "109113-72-6"
    },
    {
        "sno": "16.",
        "Chem": "2-Chloro acetoacetamide",
        "casno": "67271-66-3"
    },
    {
        "sno": "17.",
        "Chem": "2-Cyano-2-oximino-acetamide sodium salt",
        "casno": "34968-50-8"
    },
    {
        "sno": "18.",
        "Chem": "2-Imidazole carboxaldehyde",
        "casno": "10111-08-7"
    },
    {
        "sno": "19.",
        "Chem": "2-Methylphenethyl Bromide",
        "casno": "16793-90-1"
    },
    {
        "sno": "20.",
        "Chem": "2-Methoxybenzyl alcohol",
        "casno": "612-16-8"
    },
    {
        "sno": "21.",
        "Chem": "2-(5-Amino-1,2,4-thiadiazol-3-yl)-2-(methoxyimino)acetic acid",
        "casno": "72217-12-0"
    },
    {
        "sno": "22.",
        "Chem": "2,6-Dihydroxy-1-methylpurine / 1-Methyl Xanthine",
        "casno": "6136-37-4"
    },
    {
        "sno": "23.",
        "Chem": "2,6-Dimethylbenzonitrile",
        "casno": "6575-13-9"
    },
    {
        "sno": "24.",
        "Chem": "3-Acetylaminobutanoic acid ethyl ester",
        "casno": "187989-67-9"
    },
    {
        "sno": "25.",
        "Chem": "3-Aminobenzenesulfonic acid methyl ester",
        "casno": "1071289-74-1"
    },
    {
        "sno": "26.",
        "Chem": "3-Aminobenzenesulfonic acid ethyl ester",
        "casno": "1071394-78-9"
    },
    {
        "sno": "27.",
        "Chem": "3-Aminophenyl acetylene",
        "casno": "54060-30-9"
    },
    {
        "sno": "28.",
        "Chem": "3-Fluoro-5-nitrobenzotrifluoride",
        "casno": "454-73-9"
    },
    {
        "sno": "29.",
        "Chem": "3-Hydroxy methyl-2-azabicyclo(3,1,0) Hexane-2-Carboxylic acid tert-butyl ester",
        "casno": "–--"
    },
    {
        "sno": "30.",
        "Chem": "3-Methoxy benzyl chloride",
        "casno": "–--"
    },
    {
        "sno": "31.",
        "Chem": "3-Methylphenethyl Bromide",
        "casno": "16799-08-9"
    },
    {
        "sno": "32.",
        "Chem": "3-Methylxanthine",
        "casno": "1076-22-8"
    },
    {
        "sno": "33.",
        "Chem": "3-Methyl-5-nitro-7-azaindole",
        "casno": "1288998-66-2"
    },
    {
        "sno": "34.",
        "Chem": "3-Piperazin-1-yl-benzo[d]isothiazole",
        "casno": "87691-87-0"
    },
    {
        "sno": "35.",
        "Chem": "3,5-Dimethylbenzonitrile",
        "casno": "22445-42-7"
    },
    {
        "sno": "36.",
        "Chem": "4-Bromo-2-fluoro acetophenone",
        "casno": "625446-22-2"
    },
    {
        "sno": "37.",
        "Chem": "4-Bromo indole",
        "casno": "52488-36-5"
    },
    {
        "sno": "38.",
        "Chem": "4-Chlorobenzenesulfonyl chloride",
        "casno": "98-60-2"
    },
    {
        "sno": "39.",
        "Chem": "4-Chloro-2-fluorobenzoic acid",
        "casno": "446-30-0"
    },
    {
        "sno": "40.",
        "Chem": "4-Chloro-2-fluorobenzoic acid",
        "casno": "5152-84-1"
    },
    {
        "sno": "41.",
        "Chem": "4-Chloro-1,3-benzothiazole",
        "casno": "3048-45-1"
    },
    {
        "sno": "42.",
        "Chem": "4-Chlorothiophene-2-carboxylic acid",
        "casno": "59614-95-8"
    },
    {
        "sno": "43.",
        "Chem": "4-Imidazole carboxaldehyde",
        "casno": "3034-50-2"
    },
    {
        "sno": "44.",
        "Chem": "4-(methylthio)benzene-1,2-diamine",
        "casno": "67469-02-7"
    },
    {
        "sno": "45.",
        "Chem": "4-Methyl phenethyl Bromide",
        "casno": "6529-51-7"
    },
    {
        "sno": "46.",
        "Chem": "4-Methoxy benzyl chloride",
        "casno": "824-94-2"
    },
    {
        "sno": "47.",
        "Chem": "4-Methoxy-3-Cyclopentoxy Benzaldehyde",
        "casno": "5922-56-5"
    },
    {
        "sno": "48.",
        "Chem": "4-Nitro-7-azaindole",
        "casno": "55052-28-3"
    },
    {
        "sno": "49.",
        "Chem": "4-(4-Chlorobenzhydryl)Piperazine",
        "casno": "303-26-4"
    },
    {
        "sno": "50.",
        "Chem": "5-Acetonyl-2-methoxybenzene",
        "casno": "116091-63-5"
    },
    {
        "sno": "51.",
        "Chem": "5-Bromoisatin",
        "casno": "87-48-9"
    },
    {
        "sno": "52.",
        "Chem": "5-fluoroisatin",
        "casno": "443-69-6"
    },
    {
        "sno": "53.",
        "Chem": "5-Chloro indole",
        "casno": "17422-32-1"
    },
    {
        "sno": "54.",
        "Chem": "5-Chlorothiophene-2-carboxylic acid",
        "casno": "24065-33-6"
    },
    {
        "sno": "55.",
        "Chem": "5-Fluoro indole",
        "casno": "399-52-0"
    },
    {
        "sno": "56.",
        "Chem": "5-Nitro-7-azaindole",
        "casno": "101083-92-5"
    },
    {
        "sno": "57.",
        "Chem": "6-Chloro indole",
        "casno": "17422-33-2"
    },
    {
        "sno": "58.",
        "Chem": "6-Cyano-7-azaindole",
        "casno": "189882-33-5"
    },
    {
        "sno": "59.",
        "Chem": "6-Methoxy indole",
        "casno": "3189-13-7"
    },
    {
        "sno": "60.",
        "Chem": "7-Bromo-1H-indazole",
        "casno": "53857-58-2"
    },
    {
        "sno": "61.",
        "Chem": "Acetone dimethyl acetyl / 2,2-Dimethoxypropane",
        "casno": "77-76-9"
    },
    {
        "sno": "62.",
        "Chem": "Bromonitromethane",
        "casno": "563-70-2"
    },
    {
        "sno": "63.",
        "Chem": "Ethyl indole-2-carboxylic Acid",
        "casno": "1477-50-5"
    },
    {
        "sno": "64.",
        "Chem": "Indole-3-carboxylic acid",
        "casno": "771-50-6"
    },
    {
        "sno": "65.",
        "Chem": "Indole-7-carboxylic acid",
        "casno": "1670-83-3"
    },
    {
        "sno": "66.",
        "Chem": "Indole 3-carboxaldehyde",
        "casno": "487-89-8"
    },
    {
        "sno": "67.",
        "Chem": "L-Homocystine, trifluoroacetyl dimethyl ester",
        "casno": "626-72-2"
    },
    {
        "sno": "68.",
        "Chem": "L-Tryptophan",
        "casno": "73-22-3"
    },
    {
        "sno": "69.",
        "Chem": "Methyl 5-Chloro-3-Chlorosulfonyl-Thiophene-2-Carboxylate",
        "casno": "–--"
    },
    {
        "sno": "70.",
        "Chem": "Methyl 6-ethylnicotinate",
        "casno": "–--"
    },
    {
        "sno": "71.",
        "Chem": "N-Boc-3-Fluoro Piperidone / 1-Boc-3-fluoro-4-oxopiperidine",
        "casno": "211108-50-8"
    },
    {
        "sno": "72.",
        "Chem": "N-Desbutyldronedarone hydrochloride",
        "casno": "197431-02-0"
    },
    {
        "sno": "73.",
        "Chem": "N-(tert-Butyl)hydroxylamine acetate",
        "casno": "253605-31-1"
    },
    {
        "sno": "74.",
        "Chem": "N-(tert-Butyl)hydroxylamine",
        "casno": "16649-50-6"
    },
    {
        "sno": "75.",
        "Chem": "Benzoxazine derivatives",
        "casno": "–--"
    },
    {
        "sno": "76.",
        "Chem": "Carbazole derivatives",
        "casno": "–--"
    },
    {
        "sno": "77.",
        "Chem": "Carbazole derivatives",
        "casno": "–--"
    },
    {
        "sno": "78.",
        "Chem": "Oxazole derivatives",
        "casno": "–--"
    },
    {
        "sno": "79.",
        "Chem": "Oxindole derivatives",
        "casno": "–--"
    }
];                          
