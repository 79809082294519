import React from 'react'
import Header from '../components/Header'
import carrer from '../components/images/carrer.jpg';
import carrerImage from '../components/images/carreer-icon.png'


function Career() {
  return (
    <div>
        <Header
         bkgImage={carrer}
         title="Career"
         subtitle="Join SGMR and enhance your career possiblities"/>
          <div class='contact-form'>
               <ul>
                <li>
                    <form>
                        <h2>Interested to work with us?</h2>
                        <p>Fill the form below we have currently 32 openings for different posts.</p>
                        
                        <input className='input' placeholder='Name' />
                        <input className='input' placeholder='Email' type='email' />
                        <input className='input' placeholder='Phone' type="phone" />
                        <input className='input' placeholder='Phone' type="file" />
                         
                        <button type="submit" className='form-btn'>
                            Submit
                        </button>
                    


                    </form>

                </li>
                <li  >
                     <img style={{height:'300px'}} src={carrerImage} />
                </li>
               </ul>
            </div>
    </div>
  )
}

export default Career