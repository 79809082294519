import React from 'react'

function VideoIntro() {
  return (
    <>
    <div className='video-intro' >
        <ul>
        <li>
            <img src='https://www.aivahthemes.com/site-templates/labora/assets/images/photo2.jpg'/>
        </li>
        <li>
            <div className='video-intro-content'>
                <h4>We are Truested by World's Leading Companies</h4>
                 <ul>
                    <li>
                    <p>At the core of our business is a deep and unwavering commitment to earning the trust of the world’s most recognized companies. Our journey to becoming a trusted partner of global industry leaders has been shaped by our dedication to delivering consistently reliable, innovative, and future-proof solutions. From cutting-edge technology to seamless user experiences, we work tirelessly to meet the complex and evolving needs of our clients. By aligning ourselves with the goals and visions of these esteemed organizations, we have established long-standing relationships that have become the cornerstone of our success.</p>
                    <p>Our clients choose us not only for our technical expertise but for our ability to understand their industries and provide strategic insights that help drive their business forward. Whether it's in technology, finance, healthcare, or manufacturing, we understand that no two industries are the same, and that’s why we tailor our approach to each client. We take the time to deeply understand their business, challenges, and opportunities so that our solutions are not just effective but transformative</p>


                    </li>
                 </ul>
            </div>
        </li>
        </ul>
    </div>
    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1d3d74" fill-opacity="1" d="M0,192L48,202.7C96,213,192,235,288,213.3C384,192,480,128,576,112C672,96,768,128,864,160C960,192,1056,224,1152,202.7C1248,181,1344,107,1392,69.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> */}
    </>
  )
}

export default VideoIntro