import React from "react";
import Logo from "./Logo";
import FaceBookIcon from "./svgs/FaceBookIcon";
import Instagram from "./svgs/Instagram";
import Twitter from "./svgs/Twitter";
import Youtube from "./svgs/Youtube";
import { Link } from "react-router-dom";
import Home from "../pages/Home";
import { LinkIcon } from "@heroicons/react/16/solid";
import Linkedin from "../components/svgs/Lunkedin"


function Footer() {
  return (
    <footer>
      <div className="footer">
        <ul>
          <li>
            <div className="footer-item">
              {/* <Logo/> */}

  
            
              <h4>Our Address:</h4>
              <p>
                {" "}
                <strong>INDIA: </strong> 501c, 5th Floor, Bankers Chambers, AS
                Raju Nagar, Kukatpally, Hyderabad - 500072, Telangana, India.
                Ph: 040-23061889
              </p>
              <br></br>
              <p>
                <strong>UAE: </strong>Capital Golden Tower - Office 906,
                Business Bay – Dubai, United Arab Emirates Mob: +971585396686
              </p>
              <br></br>

              <p>
                {" "}
                <strong>UK: </strong> Appt. 615 Jefferson Place 1 Fernie Street,
                Manchester, United Kingdom, M4 4BL Ph: 00447424830752
              </p>

              <br></br>
              <p>
                <strong>Contact:</strong> 040-23061889, +91 9032001889
              </p>

              <p>
                <strong>Mail:</strong> api.marketing@sgmrpharma.com
              </p>
            </div>
          </li>

          <li>
            <div className="footer-item">
              <h4>Important links</h4>
              <ul>
                <Link to="/home">Home</Link>
                <Link to="/products">Products</Link>
                <Link to="/research">R&D</Link>
                <Link to ="/globalpre">Global Presence</Link>
                <Link to="/contact">Contact Us</Link>
              </ul>
            </div>
          </li>

          <li>
            <div className="footer-item">
              <h4>Follow Us</h4>
              <ul className="social-linked" style={{ flexDirection: "row !important" }}>
                <li>
                
                  <a href="https://www.facebook.com/sgmrpharma" target="_black">
                    <FaceBookIcon/>
                  </a>
                </li>
                <li>
                 
                  <a
                    href="https://www.linkedin.com/in/sgmr-pharmaceuiticals-5391b1114"
                    target="_black"
                  >
                   <Linkedin/>
                  </a>
                </li>
                <li>
                 
                  <a href="https://twitter.com/sgmr_pharma" target="_black">
                    <Twitter/>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <p>
        <center>
          SGMR Pharmaceuticals Private Limited © 2024 | All Rights Reserved
        </center>
      </p>
    </footer>
  );
}

export default Footer;
