import React from "react";
import hero1 from "../components/images/hero-1.jpg";
import hero2 from "../components/images/hero-2.jpg";
import happyGroup from "../components/images/globalimg.png";
import india from "../components/images/indiaanduk.png";
import russia from "../components/images/russia.png";
import latin from "../components/images/latiinamerica.png";
import southasia from "../components/images/southAsia.png";
import middleEast from "../components/images/middleeast.png";
import africa from "../components/images/africa.png";
import office from "../components/images/office.jpeg"
import { useEffect } from "react";
import Reasearch from '../components/images/R&D.jpeg'

function Research() {

  useEffect(() => {
    const targetElem = document.getElementById("R&D");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      <div
        className="carousel"
        style={{ backgroundImage: `url(${Reasearch})` }}
      >
        <div className="carousel-content">
          <div>
            <p className="line1">
              Join us on our journey as we plan to emprove the wellness of human
              kind overall.
            </p>
          </div>
          <div>
            <h1>
            Research & Development
            </h1>
          </div>
        </div>
      </div>

      <div className="Globalpre" id="R&D">
        <h2>Research & Development</h2>
        <ul>
          <li>
            <p>
            We are having around 25 highly professional and experienced scientists in our R & D centre which is located in Aleap Ind Est. NearPragathi Nagar, Hyderabad.
            </p>
            <p>Our research and development endeavours are built on the three pillars of innovation, customization and highest quality. As diseases develop resistance to existing drugs over a period of time, innovation helps to fight diseases effectively. As per the drug combinations that change with each customer’s production requirements, strong customization has become very needful.</p>
          </li>
          <li className="research-img">
           <img className="research-img" src={office}/>
          </li>
          <li>
            {" "}
            <p>
            Quality is integral to our production, distribution, sales and supply, and follow-up with each customer in the US, Europe, Latin America, Africa, and Asian countries. With a rigid emphasis on document control, issuance and archival, we ensure trust in our customers. Our compliance mechanism leverages on strict adherence to Internal Audits and Process validations.
            </p>
            <p>Our emphasis on quality production, distribution and customer support has helped us to sustain customer confidence in us and helped us to further our research and development for the promotion of API products and Intermediates.</p>
            <p>No product, API and Intermediates are released without proper quality checks and geographical certifications. We adhere to the highest quality standards, which are fairly accepted in the US, Africa, Latin America, Europe, and Asian countries.</p>
            <p>The primary focus of SGMR Pharmaceuticals Pvt Ltd’s research and development is to achieve innovation and develop ingredients for life-saving and life-prolonging medicines. We have achieved complete innovation and quality in the production of our flagship APIs and Intermediates.</p>
            <p>As our API and Intermediates cater to the immediate requirements of companies/customers based in the US, Africa, Latin America, Europe, and Asian Countries, the challenges of quality delivery and adherence to timelines are of immediate importance. Our Research and Development process brings together the best infrastructure facilities and proven expertise of scientific personnel and drug research specialists.</p>
            
            <p>Global acceptance and compliance of API and Intermediates are dependent on adherence to the highest norms of quality. We produce API and Intermediates as per the ICH guidelines.</p>
          
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Research;
