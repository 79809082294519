import React from 'react'
import whoWeAre from '../components/images/who-we-are.png'

function OurPresence() {
  return (
    <>
    <div style={{marginTop:'200px'}}>

    </div>

    <div className='journey-intro'>
        <ul>
        <li>
            <img src={whoWeAre} style={{width:'auto'}}/>
        </li>
        <li>
    <div className='journey-content' style={{alignItems: 'start'}}>
        <h1>Our Presence</h1>
        <p><strong>Striving for Excellence</strong></p>
        <p>
            As we embark on this transformative journey, our unwavering commitment to excellence in pharmaceutical research, development, and production remains at the forefront of our mission. With our latest investments, we are poised to enhance our capabilities and deliver even more life-changing medications to those in need.
        </p>
        <p><strong>Our Commitment</strong></p>
        <p>
            Our dedication to excellence is reflected in every step we take. We continuously aim to push the boundaries of what is possible in pharmaceutical innovation, ensuring that we meet the evolving needs of patients and healthcare providers alike.
        </p>
        <p><strong>Driving Innovation and Integration</strong></p>
        <p>
            Through strategic investments and a focus on cutting-edge research, we are integrating advanced technologies into our processes. This approach allows us to streamline development and enhance the effectiveness of our solutions, ultimately leading to improved health outcomes.
        </p>
        <a href=''>Learn More</a>
    </div>
</li>

        
        </ul>
    </div>
    </>
  )
}

export default OurPresence