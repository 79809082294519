import React from 'react'
import teamBkg from './images/team-background.jpg';
import ourCeo from './images/our-ceo.jpg';

function OurManagement() {
  return (
    <>
    <div className='our-management' style={{backgroundImage:`url(${teamBkg})`}} id="4">
       <div className='our-management-content'>
       <h1>Board Of Directors</h1>
       <ul>
        <li>
            <ul className='our-management-content-2'>
                <li>
                    <div>
                        <h3>
                        G. Mutha Reddy</h3>
                        <h4>(International Marketing Director)</h4>
                        <br></br>
                        <p>Mr. GM Reddy having 15 years of rich experience in Pharmaceutical International marketing with strong clientele relationships. He has done MBA in International marketing from Osmania University. He is working as an International marketing director and taking care of Europe, the US, MENA, CIS, and GCC region markets.</p>
                    </div>
                </li>
                <li>
                    <div>
                    <h3>
                    G Poornima</h3>
                        <h4>(Purchase Director)</h4>
                        <br></br>
                        <p>Mrs. G Poornima having rich experience in Purchase and Administration activities. She is taking care of the raw material purchase and Administration.</p>
                    </div>
                </li>
            </ul>
        </li>

       </ul>
       </div>
    </div>
    </>
  )
}

export default OurManagement