import React, { useEffect } from 'react';
import teamImage from '../components/images/teams.jpg';
import Header from '../components/Header';
import Vision from '../components/Vision';
import JourneyIntro from '../components/JourneyIntro';
import WhoWeAre from '../components/WhoWeAre';
import teamBkg from '../components/images/team-background.jpg';
import OurManagement from '../components/OurManagement';
import OurPresence from '../components/OurPresence';
import innovation from '../components/images/innovation.jpg';




function Innovation({id}) {
    useEffect(()=>{
      const targetElem = document.getElementById(id);
    //   targetElem.scrollIntoView({behavior:'smooth'});
    },[id])
  return (
    <div className='about'>
    <Header bkgImage={innovation} title="Innovation" subtitle="Know everything you need to know about us"/>
     
    <div className='full-page' id="2">
      <WhoWeAre/>
    </div>
     
    </div>
  )
}

export default Innovation