import React from "react";
import visionIcon from "../components/images/vision.png";
import missionIcon from "../components/images/mission.png";
import valueIcon from "../components/images/values.png";


const CompanyInfo = () => {
  return (
    <div className="company-info">
      <div className="info-section">
      <img className="Vision-icon" src={visionIcon} />
        <h2>Vision</h2>
        <p>
          Our vision is to become an innovative and reliable pharmaceutical company. 
          SGMR Pharmaceuticals Pvt Ltd wants to excel in the manufacturing of the highest quality products 
          with ethical standards and obligations to do right and fair and to become the most preferred supply partner 
          to our customers and achieve sustained growth, through consistent delivery of innovative quality of products.
        </p>
      </div>
      <div className="info-section">
      <img className="Vision-icon" src={missionIcon} />
        <h2>Mission</h2>
        <p>
          Our mission is to produce the most effective quality as per the client specifications 
          inside the most effective doable worth. To be a globally idolize company, providing quality products 
          to our customers and folks of the globe. To deliver internationally competitive products with the best possible price. 
          We wish to be recognized as a company committed to the values of quality and competence, 
          striving to improve the quality of life.
        </p>
      </div>
      <div className="info-section">
      <img className="Vision-icon" src={valueIcon} />
        <h2>Our Expertise</h2>
        <p>
          At present, more than 26 products are being manufactured here with 120 members of staff. 
          We have a WHO GMP approved Loan License manufacturing facility. The state of art infrastructure equipment 
          we are having and also we are manufacturing our APIs and Intermediates in this facility. 
          The International Airport is situated very close which allows early transport and delivery.
        </p>
      </div>
    </div>
  );
};

export default CompanyInfo;
