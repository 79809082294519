import React from 'react'
import HeroCarousel from '../components/HeroCarousel'
import JourneyIntro from '../components/JourneyIntro'
import VideoIntro from '../components/VideoIntro' 
import Products from '../components/Products'
import Porfolio from '../components/Porfolio'
import Cta from '../components/Cta' 
import OurServices from '../components/OurServices'

function Home() {
  return (
    <>
    <HeroCarousel/>
    <JourneyIntro/>
    <VideoIntro/>
    <OurServices />
    {/* <Products/>  */}
    {/* <Cta/> */}
    </>
  )
}

export default Home