import React from 'react'
import whoWeAre from '../components/images/who-we-are.png'

function WhoWeAre() {
  return (
    <>
    <div className='journey-intro'>
        <ul>
        <li>
            <div className='journey-content' style={{alignItems:'start'}}>
                <h2>Core Strengths:</h2>
                <label className='Core_Strengths'>
                SGMR Pharmaceuticals Pvt Ltd specializes in the exclusive manufacturing of APIs and intermediates. We leverage novel processes, proven competencies, and state of art facilities for the production of API Products. We bring phenomenal value to our customers in Europe and Businesses in terms of cost, speed, and quality API Products. We adhere to the highest standards of ethics and integrity.
                </label>
                <h4>
                Our core strengths include:
                </h4>
                <ol className='core_strengths'>
                    <li>Experienced team with over two decades of business and technical experience.</li>
                    <li>Cleanroom air handling units with a focus on high quality.</li>
                    <li>Dedicated manufacturing facilities for the production of API products and Intermediates.</li>
                    <li>Focus on the assessment of drug (API) manufacturing cost and yield optimization.</li>
                    <li>API process development from basic raw materials.</li>
                </ol>
                {/* <a href=''>
                    Learn More
                </a> */}
            </div>
        </li>
        {/* <li>
            <img src={whoWeAre} style={{width:'auto'}}/>
        </li> */}
        </ul>
    </div>
    </>
  )
}

export default WhoWeAre