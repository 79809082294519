import React from 'react'
import Header from '../components/Header'
import media from '../components/images/media.jpg'
import news from '../components/images/news.jpg';
import { Link } from 'react-router-dom';


function Media() {
  return (
    <div>
        <Header
         bkgImage={media}
         title="Media"
         subtitle="Read to our reaches to world here"/>
         <div className='media'>
          <ul>
            <li>
              <div className='media-card'>
              <div className="" style={{backgroundImage: `url(${news})`, backgroundSize: 'cover',height:'250px'}}>
                                 . 
                              </div>
                <div className='media-content'>
                  <h1>News Title News title</h1>
                  <p>Sample Description Over Here ,Over Here , sampOver Here , sampOver Here , sampOver Here , samp sample description over here</p>
                </div> 
              </div>
            </li>
            <li>
              <div className='media-card'>
              <div className="" style={{backgroundImage: `url(${news})`, backgroundSize: 'cover',height:'250px'}}>
                                 . 
                              </div>
                <div className='media-content'>
                  <h1>News Title News title</h1>
                  <p>Sample Description Over Here ,Over Here , sampOver Here , sampOver Here , sampOver Here , samp sample description over here</p>
                </div> 
              </div>
            </li>
            <li>
              <div className='media-card'>
              <div className="" style={{backgroundImage: `url(${news})`, backgroundSize: 'cover',height:'250px'}}>
                                 . 
                              </div>
                <div className='media-content'>
                  <h1>News Title News title</h1>
                  <p>Sample Description Over Here ,Over Here , sampOver Here , sampOver Here , sampOver Here , samp sample description over here</p>
                </div> 
              </div>
            </li>
          </ul>
         </div>

    </div>
  )
}

export default Media